/*
 * Essential Imports
 */

import 'picturefill';
import 'lazysizes';
import './vendor/ls.object-fit';


/*
 * Essentail Fixes
 */

// Preload and No-JS
setTimeout(function () {
    document.documentElement.classList.remove('preload');
    document.documentElement.classList.remove('no-js');
}, 300);

let resizeId;
window.addEventListener('resize', function () {
    clearTimeout(resizeId);
    document.documentElement.classList.add('preload');
    resizeId = setTimeout(function () {
        document.documentElement.classList.remove('preload')
    }, 300);
});

// Fix NodeList.forEach in IE11
if (window.NodeList && !NodeList.prototype.forEach) {
    NodeList.prototype.forEach = Array.prototype.forEach;
}


/*
 * Cookies
 */
import 'js-cookie';

let cookieNotice = document.getElementById('cookie-notice');
if(cookieNotice != 'undefined' && cookieNotice != null && Cookies.get('cookie-notice') !== 'true') {
    document.querySelectorAll('.js-cookie_close').forEach(el => {
        el.addEventListener('click', event => {
            event.preventDefault();
            Cookies.set('cookie-notice', 'true', { expires : 7 });
            cookieNotice.classList.add('hide');
        }, false);
    });
}

let bottomNotice = document.getElementById('bottom-notice');
if(bottomNotice != 'undefined' && bottomNotice != null && Cookies.get('bottom-notice') !== 'true') {
    document.querySelectorAll('.js-notice_close').forEach(el => {
        el.addEventListener('click', event => {
            event.preventDefault();
            Cookies.set('bottom-notice', 'true', { expires : 7 });
            bottomNotice.classList.add('hide');
        }, false);
    });
}

let drawerNoticeStorage = sessionStorage.getItem('drawer_notice');
if (!drawerNoticeStorage) {
    let drawerNotice = document.getElementById('drawer-notice');
    if(drawerNotice != 'undefined' && drawerNotice != null) {
        window.addEventListener('load', (event) => {
            setTimeout(function () {
                drawerNotice.classList.add('show');
            }, 2500);
        });

        document.querySelectorAll('.js-drawer_close').forEach(el => {
            el.addEventListener('click', event => {
                sessionStorage.setItem('drawer_notice', 'true');
                drawerNotice.classList.remove('show');
            }, false);
        });
    }
}

/*
let drawerNotice = document.getElementById('drawer-notice');
if(drawerNotice != 'undefined' && drawerNotice != null && Cookies.get('drawer-notice') !== 'true') {
    window.addEventListener('load', (event) => {
        setTimeout(function () {
            drawerNotice.classList.add('show');
        }, 2500);
    });

    document.querySelectorAll('.js-drawer_close').forEach(el => {
        el.addEventListener('click', event => {
            Cookies.set('drawer-notice', 'true', { expires : 7 });
            drawerNotice.classList.remove('show');
        }, false);
    });
}
*/


/*
 * FlashMessage
 */

import FlashMessage from './functions/flash-message';
const flash_message = new FlashMessage();


/*
 * Forms
 */

// FormValidation
import './functions/form-validation';
document.querySelectorAll('.form.js-validate').forEach(form => {
    new Validate(form, {
		'recaptchaSiteKey': document.querySelector("meta[name='recaptcha_site_key']").getAttribute('content'),
		'fieldFeedbackSelector': '.js-field-feedback span'
	});
});

// In-field Labels
document.querySelectorAll('.js-infield').forEach(field => {
    field.addEventListener('keyup', event => {
        field.setAttribute('value', field.value);
    });
});

// Email / Telephone required behaviour
let fieldEmail = document.getElementById('contact_email_address');
let fieldTel = document.getElementById('contact_telephone_number');

if (fieldEmail && fieldTel) {
    fieldEmail.addEventListener('change', function() {
        if (fieldEmail.value != '') {
            fieldTel.required = false;
        } else {
            fieldTel.required = true;
        }
    });
    fieldTel.addEventListener('change', function() {
        if (fieldTel.value != '') {
            fieldEmail.required = false;
        } else {
            fieldEmail.required = true;
        }
    });
}


/*
 * Focus
 */

document.querySelectorAll('.js-focus').forEach(elem => {
    let focus = elem.dataset.focus;

    elem.addEventListener('click', event => {
        setTimeout(function () {
            document.getElementById(focus).focus();
        }, 100);
    });
});


/*
 * GoTo Elem
 */

if(typeof(document.querySelector('.js-goto_elem')) != 'undefined' && document.querySelector('.js-goto_elem') != null) {
    document.querySelectorAll('.js-goto_elem').forEach(el => {
        el.addEventListener('click', event => {
            let elemId = el.dataset.elem;
            let headerHeight = document.querySelector('.header').offsetHeight;
            let stickyHeight = 0;

            if(typeof(document.querySelector('.js-sticky')) != 'undefined' && document.querySelector('.js-sticky') != null)
                stickyHeight = document.querySelector('.js-sticky').offsetHeight;

            scrollToElem(document.getElementById(elemId), (headerHeight + stickyHeight));
        });
    });
}

// On Page  Load
if(window.location.hash) {
    let hash = window.location.hash.substring(1);
    let headerHeight = document.querySelector('.header').offsetHeight;
    let stickyHeight = 0;

    if(typeof(document.querySelector('.js-sticky')) != 'undefined' && document.querySelector('.js-sticky') != null)
        stickyHeight = document.querySelector('.js-sticky').offsetHeight;

    setTimeout(function () {
        scrollToElem(document.getElementById(hash), (headerHeight + stickyHeight));
    }, 1);
}


/*
 * Modal Window
 */

import ModalWindow from './functions/modal-window';
const modal_window = new ModalWindow();


/*
 * Scroll Tabs
 */

window.addEventListener('scroll', function (e) {
    if(typeof(document.querySelector('.js-scroll-tab')) != 'undefined' && document.querySelector('.js-scroll-tab') != null) {
        document.querySelectorAll('.js-scroll-tab').forEach(scroll_tab => {
            let section = scroll_tab.dataset.elem;
            let section_pos = document.getElementById(section).offsetTop;

            if (window.scrollY >= section_pos) {
                let siblings = elemSiblings(scroll_tab.parentNode);
                siblings.forEach(sibling => sibling.classList.remove('is-current'));
                scroll_tab.parentNode.classList.add('is-current');
            }
        });
    }
});


/*
 * Snow
 */

function getRandom(min, max) {
    return Math.random() * (max - min) + min;
  }

  (function letItSnow(){
    var snowflakes = document.querySelectorAll('.snow');
    for (var i = 0; i < snowflakes.length; i++) {
      var snowflake = snowflakes[i];
      snowflake.setAttribute('cx', getRandom(1,100) + '%');
      snowflake.setAttribute('cy', '-' + getRandom(1,100));
      snowflake.setAttribute('r', getRandom(1,3));
    }
  })();


/*
 * StickyHeader
 */

import StickyHeader from './functions/sticky-header';
const sticky_header = new StickyHeader();


/*
 * Swiper
 */

import './vendor/swiper';
document.querySelectorAll('.js-swiper').forEach(swiper => {
    let swiperID = swiper.id;
    let params = swiper.dataset.swiper;
    let mySwiper = new Swiper(swiper, JSON.parse(params));

    // Swiper Height
    document.addEventListener('lazyloaded', function (e) {
        if(swiper.dataset.autoheight)
            mySwiper.updateAutoHeight();
    });
});

// Swiper GOTO
document.querySelectorAll('.js-swiper_goto').forEach(goto => {
    goto.addEventListener('click', event => {
        event.currentTarget.blur();

        let swiperSlider = JSON.parse(goto.dataset.slider);
        let swiperSlide = goto.dataset.slide;

        for (var i = 0; i < swiperSlider.length; i++) {
            let mySwiper = document.getElementById(swiperSlider[i]).swiper;
            mySwiper.slideTo(swiperSlide);
        }
    });
});


/*
 * Tab
 */

if(typeof(document.querySelector('.js-tab_item')) != 'undefined' && document.querySelector('.js-tab_item') != null) {
    document.querySelectorAll('.js-tab_item').forEach(el => {
        el.addEventListener('click', event => {
            let elSiblings = elemSiblings(el);
            elSiblings.forEach(sibling => sibling.classList.remove('is-current'));
            el.classList.add('is-current');
        });
    });
}


/*
 * Tabbing
 */

const handleFirstTab = (e) => {
    if (e.keyCode === 9) {
        document.body.classList.add('has-tabbing-user');

        window.removeEventListener('keydown', handleFirstTab);
        window.addEventListener('mousedown', handleMouseDownOnce);
    }
};

const handleMouseDownOnce = () => {
    document.body.classList.remove('has-tabbing-user');

    window.removeEventListener('mousedown', handleMouseDownOnce);
    window.addEventListener('keydown', handleFirstTab);
};

window.addEventListener('keydown', handleFirstTab);


/*
 * Video
 */

import Video from './functions/video';
const video = new Video();


/* ------------------------------------------------------------------------------------------------------------------------ */
/* VIEWPORT --------------------------------------------------------------------------------------------------------------- */
/* ------------------------------------------------------------------------------------------------------------------------ */

// Define our viewportWidth variable
let viewportWidth;

// Set/update the viewportWidth value
let setViewportWidth = function () {
	viewportWidth = window.innerWidth || document.documentElement.clientWidth;
}

// Log the viewport width into the console
let logWidth = function () {
    if(typeof(document.querySelector('.js-viewport_swap')) != 'undefined' && document.querySelector('.js-viewport_swap') != null) {
        document.querySelectorAll('.js-viewport_swap').forEach(el => {
            let originalElement = el;
            let originalElementClone = el.cloneNode(true);
            let swapElement = document.getElementById(el.dataset.swap);
            let swapElementClone = swapElement.cloneNode(true);

            if (viewportWidth > 768) {
                if (originalElementClone.classList.contains('is-swapped')) {
                    swapElement.parentNode.replaceChild(originalElementClone, swapElement);
                    originalElement.parentNode.replaceChild(swapElementClone, originalElement);

                    originalElementClone.classList.remove('is-swapped');
                }
            } else {
                if (!originalElementClone.classList.contains('is-swapped')) {
                    swapElement.parentNode.replaceChild(originalElementClone, swapElement);
                    originalElement.parentNode.replaceChild(swapElementClone, originalElement);

                    originalElementClone.classList.add('is-swapped');
                }
            }
        });
    }
}

// Set our initial width and log it
setViewportWidth();
logWidth();

// On resize events, recalculate and log
window.addEventListener('resize', function () {
	setViewportWidth();
	logWidth();
}, false);


/* ------------------------------------------------------------------------------------------------------------------------ */
/* SUPPORT FUNCTIONS ------------------------------------------------------------------------------------------------------ */
/* ------------------------------------------------------------------------------------------------------------------------ */


/*
 * scrollToElem
 */

const scrollToElem = (elem, offset) => {
    const y = elem.getBoundingClientRect().top + window.pageYOffset - offset;
    window.scrollTo({
        top : y,
        behavior : 'smooth'
    });
};


/*
 * elemSiblings
 */

const elemSiblings = n => [...n.parentElement.children].filter(c=>c!=n);

/*
 * GA Events
 */

import './functions/gaEvents';
