'use strict';

export default class StickyHeader {
	constructor() {
		if (window.pageYOffset > 0)
			document.querySelector('body').classList.add('is-sticky-header');

		window.addEventListener('scroll', function() {
		    if (window.pageYOffset > 0)
				document.querySelector('body').classList.add('is-sticky-header');
			else
				document.querySelector('body').classList.remove('is-sticky-header');
		});
	}
}
