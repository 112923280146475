'use strict'

export default class ModalWindow {
	constructor() {
		this.listeners();
	}

	listeners() {
		document.addEventListener('click', this.toggleModalWindow, false);
		document.addEventListener('keydown', this.keyDown);
		document.addEventListener('click', this.backdropClose, false);
	}

	keyDown(el) {
		if(27 === el.keyCode) {
			document.querySelectorAll('.js-modal_wrapper').forEach(el => {
				el.classList.contains('open');
				ModalWindow.closeModalWindow();
			});
		}
	}

	backdropClose(el) {
		document.querySelectorAll('.js-modal_wrapper').forEach(elem => {
			if(elem == el.target && elem.classList.contains('open'))
				ModalWindow.closeModalWindow();
		});
	}

	toggleModalWindow(el) {
		for (var target = el.target; target && target != this; target = target.parentNode) {
			if (target.matches('.js-modal_trigger')) {
				target.blur();

				lockScroll();

				let modalID = target.dataset.modal;
				let modal = document.getElementById(modalID);

				if(modal.classList.contains('open')) {
					unlockScroll();
					modal.classList.toggle('open');
				} else {
					lockScroll();
					modal.classList.toggle('open');
				}

				checkModalHeight(modal);
				window.addEventListener('resize', function(){ checkModalHeight(modal) });

				break;
			}
		}
	}

	static closeModalWindow() {
		document.querySelectorAll('.js-modal_wrapper.open').forEach(el => {
			el.classList.remove('open');
			unlockScroll();
		});
	}
}

// Check Modal Height
function checkModalHeight(modal) {
	let heightBody = window.innerHeight;
	let heightModalContent = modal.querySelector('.js-modal_content').offsetHeight;

	if (heightModalContent >= heightBody)
		modal.classList.add('fixedHeight');
	else
		modal.classList.remove('fixedHeight');
}

// LockScroll
function lockScroll() {
	document.querySelector('html').setAttribute('data-scroll-current', window.scrollY);
	window.addEventListener('scroll', noScroll);
}

// UnlockScroll
function unlockScroll() {
	window.removeEventListener('scroll', noScroll);
}

// NoScroll
function noScroll() {
	window.scroll(0, document.querySelector('html').dataset.scrollCurrent);
}
